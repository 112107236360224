import React, { createContext, useContext } from "react"
import siteData from "../../siteData"

const SiteDataCtx = createContext(null)

const SiteDataProvider = ({ children }) => {
  return (
    <SiteDataCtx.Provider value={siteData}>
      {children}
    </SiteDataCtx.Provider>
  )
}

export default SiteDataProvider
export const useSiteData = () => useContext(SiteDataCtx)