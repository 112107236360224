import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
import ProjectGridItemContent from "./ProjectGridItemContent.js"

const images = require.context("../../img", true)

const itemStyles = {
  marginTop: 30,
  background: "#fff",
  transition: "all 0.2s ease-out",
  boxShadow: "0 0 5px #ccc"
}

const ProjectGridItem = ({ project, extUrl }) => {

  const navigate = useNavigate()

  const [hoveredItem, setHoveredItem] = useState(null)
  const isHovered = project.slug === hoveredItem

  const hoveredItemStyles = isHovered ?
    {
      boxShadow: "0 0 7px #aaa",
      transform: "translateY(-0.8em)",
      cursor: "pointer"
    } :
    {}

  const thisItemStyles = {
    ...itemStyles,
    ...hoveredItemStyles,
    // marginLeft: isMobile ? null : "1.2em",
    // minWidth: 200,
    // maxWidth: 380,
    // width: isMobile ? "100%" : 0.3 * width
  }

  return (
    <div
      className={"project-grid-item"}
      style={{
        textDecoration: "none"
      }}
      onMouseOver={_ => setHoveredItem(project.slug)}
      onMouseOut={_ => setHoveredItem(null)}
      onClick={_ => extUrl ?
        window.open(extUrl, "_blank") :
        navigate(project.slug)
      }
    >
      <div style={thisItemStyles}>
        <ProjectGridItemContent
          title={project.title}
          description={project.description}
          isHovered={isHovered}
          image={
            project.gridImage ?
              project.gridImage.startsWith("https:") ?
                project.gridImage :
                `${images(project.gridImage)}` :
              null
          }
        />
      </div>
    </div>
  )
}

export default ProjectGridItem