import React, { useEffect } from "react"
import "./App.css"
import WindowDimensionsProvider from "./components/WindowDimensionsProvider"
import HomePage from "./pages/HomePage"
import ProjectPage from "./pages/ProjectPage"
// import PalmerPenguinsPage from "./pages/PalmerPenguinsPage"
// import MinimalPenguins from "./pages/PalmerPenguinsPage/minimal"
// import HealthAndWealthPage from "./pages/HealthAndWealthPage"
import NotFoundPage from "./pages/NotFoundPage"
import AboutPage from "./pages/AboutPage"
import SiteDataProvider from "./components/SiteDataProvider"
import { Routes, Route } from "react-router-dom"
import { createBrowserHistory } from "history"
import ReactGA from "react-ga"

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID)
const browserHistory = createBrowserHistory()
browserHistory.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search)
})

const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
    <div>
      <WindowDimensionsProvider>
        <SiteDataProvider>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            {/* <Route path="/palmer-penguins" element={<PalmerPenguinsPage />} />
            <Route path="/minimal-penguins" element={<MinimalPenguins />} /> */}
            {/* <Route
              path="/the-health-and-wealth-of-nations"
              element={<HealthAndWealthPage />}
            /> */}
            <Route path="/:project/" element={<ProjectPage />} />
            <Route path="/:project/*" element={<NotFoundPage />} />
          </Routes>
        </SiteDataProvider>
      </WindowDimensionsProvider>
    </div>
  )
}
export default App
