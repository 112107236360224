import React from "react"
import Layout from "../../components/Layout"
import ProjectGrid from "../../components/ProjectGrid"
import { useSiteData } from "../../components/SiteDataProvider"

const Home = () => {

  const { mainText, subText } = useSiteData()

  return (
    <Layout
      title={mainText}
      subText={subText}
    >
      <ProjectGrid />
    </Layout>
  )
}

export default Home