import React from 'react'
import ReactMarkdown from "react-markdown"
import Image from "react-graceful-image"

const ProjectGridItemContent = ({
  title,
  description,
  isHovered,
  image
}) => {

  return (
    <div>
      { image && <Image src={image} width="100%" alt={title} /> }
      <div style={{ padding: "20px 14px 6px 14px" }}>
        <h4
          style={{
            margin: 0,
            padding: 0,
            fontWeight: 600,
            fontSize: 15,
            transition: "all 0.2s ease-out",
            color: isHovered ? "#444" : "#666",
            textDecoration: isHovered ? "underline" : null
          }}
        >
          {title}
        </h4>
        <div
          style={{
            marginTop: 10,
            fontFamily: "Karla",
            color: "#777",
            fontSize: 15
          }}
        >
          <ReactMarkdown source={description} />
        </div>
      </div>
    </div>
  )
}

export default ProjectGridItemContent