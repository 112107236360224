import React from 'react'
import Layout from "../../components/Layout"
import { useParams } from "react-router-dom"
import { useSiteData } from "../../components/SiteDataProvider"
import { useWindowDimensions } from "../../components/WindowDimensionsProvider"
import CodeSandboxEmbed from "../../components/CodeSandboxEmbed"
import CodeHighlighter from "../../components/CodeHighlighter"
import NotFoundPage from "../NotFoundPage"
import ReactMarkdown from "react-markdown"
import Image from "react-graceful-image"
import "./styles.css"

const images = require.context("../../img", true)

const styles = {
  text: {
    marginLeft: 8,
    color: "#777",
    fontFamily: "Karla"
  }
}

const ProjectPage = ({children}) => {

  const { isMobile } = useWindowDimensions()

  const { project: projectSlug } = useParams()

  const project = useSiteData().projects.find(p => p.slug === projectSlug)

  const detailImgSrc = project?.detailImage ?
    project.detailImage.startsWith("https:") ?
      project.detailImage :
      `${images(project.detailImage)}` :
        null

  const detailImg = (
    detailImgSrc &&
      <div
        style={{
          border: "1px solid #efefef",
          borderRadius: 2,
          boxShadow: "0 12px 40px 8px rgba(150, 150, 150, 0.16)"
        }}
      >
        { detailImgSrc.endsWith(".mov") ?
          <video width="100%" src={detailImgSrc} controls autoPlay /> :
          <Image
            src={detailImgSrc}
            width="100%"
            style={{ display: "block" }}
            alt={`${projectSlug}-detail`}
          />
        }
      </div>
  )

  const exampleLink = (
    project && 
      <h3 style={{ ...styles.text }}>
        [
        <a
          className="lnk"
          style={{
            fontSize: isMobile ? 18 : null,
            margin: "0 2px"
          }}
          href={project.codeSandboxUrl || project.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          See it in action
        </a>
        ]
      </h3>
  )

  return (
    project ?
      <Layout
        title={
          project.imgTitle ?
            <Image
              src={images(project.imgTitle)}
              width={300}
              height={93}
              style={{ display: "block" }}
              alt={`${projectSlug}-detail`}
            /> :
            project.title
        }
        subText={project.description}
      >
        <div style={{maxWidth: 1050}}>
          { project.install && 
            <div style={{marginLeft: 8, marginBottom: 20}}>
              <ReactMarkdown source={project.install} /> 
            </div>
          }
          { project.codeSandboxUrl || project.url ?
            <div>
              {isMobile || project.url ?
                <div>
                  <a href={project.codeSandboxUrl || project.url}>
                    {detailImg}
                  </a>
                  {exampleLink}
                </div> :
                <CodeSandboxEmbed url={project.codeSandboxUrl} title={project.title} />
              }
            </div> :
            detailImg
          }
          {
            project.extraContent &&
            <div className="project-content">
              <ReactMarkdown source={project.extraContent} />
            </div>
          }
          {
            project.codeContent &&
            <div className="project-content">
              <CodeHighlighter code={project.codeContent} />
            </div>
          }
          {children}
        </div>
      </Layout> :
      <NotFoundPage />
  )
}

export default ProjectPage
