import React from 'react'
import Navbar from "../Navbar"
import { useWindowDimensions } from "../WindowDimensionsProvider"
import ReactMarkdown from "react-markdown"

const Layout = ({title, subText, children}) => {

  const { isMobile, isNarrow } = useWindowDimensions()

  return (
    <>
      <Navbar />
      <div
        style={{
          margin: 20,
          paddingTop: isNarrow ? 70 : 80,
          marginBottom: 50
        }}
      >
        <h1
          style={{
            fontSize: isMobile ? isNarrow ? 40 : 50 : null
          }}
        >
          {title}
        </h1>
        <h3
          style={{
            fontSize: isMobile ? 18 : null,
            marginLeft: isMobile ? 4 : 8,
            fontFamily: "Karla"
          }}
        >
          <ReactMarkdown source={subText} />
        </h3>
        <div style={{ marginTop: isNarrow ? 30 : 50 }}>
          {children}
        </div>
      </div>
    </>
  )
}

export default Layout
