import React from 'react'
import Layout from "../../components/Layout"
import { Link } from "react-router-dom"

const NotFoundPage = () => {

  return (
    <Layout
      title="Whoops"
      subText="Looks like that page doesn't exist."
    >
      <div style={{ marginLeft: 10, marginTop: -10 }}>
        <Link className="lnk" to="/">Head back home.</Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage
