import React from 'react'

const CodeSandboxEmbed = ({url, title}) => {
  return (
    <div style={{marginTop: 30}}>
      <iframe
        src={url}
        style={{ width: "100%", height: 500, border: "0px solid #fff", borderRadius: 4, overflow: "hidden" }}
        title={title}
        allow="accelerometer; camera; encrypted-media; geolocation; gyroscope; microphone; midi; payment; usb; xr-spatial-tracking"
        sandbox="allow-autoplay allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
      />
    </div>
  )
}

export default CodeSandboxEmbed
