import slugify from "slugify"

let siteData = {
  mainText: "Data. Delightful.",
  subText: "Making data (and working with it) a delight since 2011.",
  projects: [
    {
      imgTitle: "./graphique_logo_serif_trans.png",
      title: "Graphique",
      // install: '`$ npm install @graphique/gg @graphique/geom-line @graphique/example-datasets`',
      description: "An interactive visualization system for React based on the Grammar of Graphics",
      gridImage: "./graphique_logo_serif_padded.png",
      // detailImage: "./graphique_logo_white_on_light.png",
      // url: "https://github.com/madams1/graphique"
    },
    {
      title: "Palmer Penguins",
      description: "A reference scatterplot with group trends using Graphique",
      gridImage: "./palmer.png",
      detailImage: "./palmer_detail.png",
      codeContent: `// grouped scatterplot w/ group trends
import { GG, Labels } from "@graphique/gg"
import { GeomPoint } from "@graphique/geom-point"
import { GeomSmooth } from "@graphique/geom-smooth"
import { penguins } from "@graphique/datasets"

<GG
  data={penguins}
  aes={{
    x: d => d.flipperLength,
    y: d => d.beakLength,
    fill: d => d.species
  }} 
  useParentWidth
  margin={{left: 35}}
  height={500}
>
  <GeomPoint size={3.4} opacity={0.2} />
  <GeomSmooth se method="linear" />
  <Labels x="Flipper length (mm)"
    y="Beak length (mm)"
    title={penguinTitle} />
</GG>`
      // codeSandboxUrl: "https://codesandbox.io/embed/force-directed-particles-zxjqd?codemirror=1&editorsize=40&fontsize=14&hidenavigation=1&module=%2Fsrc%2Fbubbles.js&theme=dark"
    },
    {
      title: "The Health and Wealth of Nations",
      description: "A global look at trends in national health and economic output",
      gridImage: "./gapminder.png",
      // detailImage: "./particle_flow.gif",
      codeSandboxUrl: "https://codesandbox.io/embed/gapminder-uvq92?fontsize=14&hidenavigation=1&theme=dark",
      // codeSandboxUrl: "https://codesandbox.io/s/gapminder-uvq92?codemirror=1&editorsize=40&fontsize=14&hidenavigation=1"
    },
    {
      title: "Force-directed Particle Flow",
      description: "Useful for visualizing discrete event simulations",
      gridImage: "./particle_flow.gif",
      detailImage: "./particle_flow.gif",
      codeSandboxUrl: "https://codesandbox.io/embed/force-directed-particles-zxjqd?codemirror=1&editorsize=40&fontsize=14&hidenavigation=1&module=%2Fsrc%2Fbubbles.js&theme=dark"
    },
    {
      title: "Pudding.cool Recreation",
      description: "Gender dialog breakdown on the radio show *This American Life*",
      gridImage: "./this_american_life.png",
      detailImage: "./tal_detail.png",
      codeSandboxUrl: "https://codesandbox.io/embed/puddingcool-tal-recreation-guh0h?codemirror=1&fontsize=14&hidenavigation=1&module=%2Fsrc%2Ftal_vis.js&theme=dark"
    },
    {
      title: "Pluralsight Technology Index",
      description: "Exploring the popularity, growth, and historical context of 1,000+ technologies",
      gridImage: "./pti.png",
      detailImage: "./pti.mov"
    },
    {
      title: "d3-summary-tiles",
      // install: '`$ npm install d3-summary-tiles`',
      description: "When you want to visualize summarized data with colored tiles!",
      gridImage: "./volcano.png",
      detailImage: "./volcano_detail.png",
      url: "https://bl.ocks.org/madams1/1613db1ba5d0c315b93e8543924c415c",
      extraContent: `Summary tiles are a way of visualizing summarized, rectangular data. They are good for creating things like [correlation matrices](https://bl.ocks.org/madams1/63dd30728bbb486394097bf9a647574b), [topographic grids](https://bl.ocks.org/madams1/1613db1ba5d0c315b93e8543924c415c), and [temporal heatmaps](https://bl.ocks.org/madams1/43ceb9455849a5f3327b361469d55c7c).\n\nTake a look on [GitHub](https://github.com/madams1/d3-summary-tiles).`
    },
    {
      title: "Real-time Bus Explorer",
      description: "Buses mapped in real time for dozens of locations across the U.S.",
      gridImage: "./buses_preview.png",
      detailImage: "./buses.png",
      extraContent: `A small app made with [Node.js](https://nodejs.org/en), [Express](https://expressjs.com/), [socket.io](https://socket.io/), [Leaflet](https://leafletjs.com/), [D3.js](https://d3js.org/), and the [TransLoc API](https://rapidapi.com/transloc/api/openapi-1-2). Includes aggregate statistics for each transit system, as well as current information for each bus.`
    },
    // {
    //   title: "Cleveland Dotplot",
    //   description: "A reusable D3 chart great for rankings/comparisons",
    //   gridImage: "./dotplot.png",
    //   detailImage: "./dotplot_detail.png",
    //   codeSandboxUrl: "https://codesandbox.io/embed/reusable-dotplot-r9sep?codemirror=1&fontsize=14&hidenavigation=1&module=%2Fsrc%2Fdotplot.js&theme=dark"
    // },
    {
      title: "Calendar Heatmap",
      // install: '`$ npm install calendar_heatmap`',
      description: "Flexible D3 calendar heatmaps a la GitHub contribution calendars",
      codeSandboxUrl: "https://codesandbox.io/embed/calendar-heatmap-example-jhwuk?codemirror=1&fontsize=14&hidenavigation=1&view=preview&module=%2Fsrc%2Findex.js&theme=dark",
      gridImage: "./calendar_heatmap.png",
      detailImage: "./calendar_heatmap.png",
      extraContent: "Inspired by GitHub’s [contribution calendar](https://github.com/blog/1360-introducing-contributions), `calendar_heatmap` is used to display data measured on a daily basis. Once installed, just plug in your data to use it yourself — it’s pretty configurable and handles missing data well."
    },
    // {
    //   title: "Searchable Choropleth",
    //   description: "Use text input to find shapes in maps",
    //   gridImage: "./searchable_choropleth.png",
    //   detailImage: "./searchable_choropleth_detail.gif",
    //   codeSandboxUrl: "https://codesandbox.io/embed/searchable-choropleth-61547?codemirror=1&fontsize=14&hidenavigation=1&module=%2Fsrc%2Fmap.js&theme=dark"
    // }
  ]
}

// give each project a slug based on its title
siteData.projects = siteData.projects.map(project => {
  const slug = slugify(project.title).toLowerCase()
  return (
    {
      ...project,
      slug
    }
  )
})

export default siteData