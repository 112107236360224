import React, {useEffect, useState} from "react"
import Highlight, { defaultProps } from "prism-react-renderer"
import theme from "prism-react-renderer/themes/oceanicNext"
import { useWindowDimensions } from "../WindowDimensionsProvider"

const containerStyle = {
  marginTop: 30,
  padding: 14,
  paddingLeft: 12,
  maxWidth: "100%",
  overflowX: "overlay"
}

const lineNumStyle = {
  opacity: 0.15,
  paddingRight: 16,
  // minWidth: 16,
  textAlign: "right",
  userSelect: "none",
  display: "table-cell"
}

export default ({code}) => {

  const { isNarrow } = useWindowDimensions()
  const [showCode, setShowCode] = useState(false)

  useEffect(() => {
    setTimeout(() => setShowCode(true), 100)
  }, [])

  return (
    showCode &&
      <div>
        <Highlight {...defaultProps} theme={theme} code={code} language="jsx">
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre className={className} style={{...style, ...containerStyle}}>
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })} style={{display: "table-row"}}>
                  <span style={{...lineNumStyle, fontSize: isNarrow ? 16 : 14}}>{i + 1}</span>
                  <div style={{display: "table-cell"}}>
                    {line.map((token, key) => (
                      <span {...getTokenProps({ token, key })} />
                    ))}
                  </div>
                </div>
              ))}
            </pre>
          )}
        </Highlight>
      </div>
  )
}