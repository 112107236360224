import React from 'react'
import Layout from "../../components/Layout"
import { useWindowDimensions } from "../../components/WindowDimensionsProvider"

// const resumeUrl = "https://drive.google.com/file/d/1vt9r688YQbVGRNjiOXma82ylj4LAdI1Z/view?usp=sharing"

const AboutPage = () => {

  const { isMobile } = useWindowDimensions()

  const styles = {
    text: {
      maxWidth: 960,
      fontSize: isMobile ? 18 : null,
      marginLeft: isMobile ? 4 : 8,
      color: "#777",
      fontFamily: "Karla"
    }
  }

  return (
    <Layout
      title="About"
    >
      <div>

        <h3 style={{...styles.text}}>
          Hi, I'm Matt. I make things for people out of data and computers.
          {/* [
            <a
              className="lnk"
              style={{
                margin: "0 2px"
              }}
              href={resumeUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Résumé
            </a>

          ] */}
        </h3>
        <h3 style={{...styles.text, marginTop: 28}}>
          When I’m not working with data, chances are I’m keeping up with my wife and kids, watching (and occasionally playing) sports, learning all kinds of new things, studying comedy, or otherwise acting like I know what I’m doing.
        </h3>
      </div>
    </Layout>
  )
}

export default AboutPage
