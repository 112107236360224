import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { Github, Linkedin } from "@icons-pack/react-simple-icons"
import { ReactComponent as EmailIcon } from "../../svg/mail.svg"
import { ReactComponent as MenuIcon } from "../../svg/menu.svg"
import { ReactComponent as CloseIcon } from "../../svg/x.svg"
import { useWindowDimensions } from "../WindowDimensionsProvider"
import styles from "./Navbar.module.css"

const setLinkColor = (isHovered) => {
  return isHovered ? "#333" : "#888"
}

const Navbar = () => {

  const [homeLinkHovered, setHomeLinkHovered] = useState(null)
  const [hoveredLink, setHoveredLink] = useState(null)
  const [showMenu, setShowMenu] = useState(false)
  const navigate = useNavigate()
  const { isMobile } = useWindowDimensions()

  const links = [
    <div
      className={styles.link}
      onClick={_ => navigate("/about")}
    >
      About
    </div>,
    <a
      href="mailto:matt@mattadams.io"
      className={styles.link}
    >
      <div style={{ width: isMobile ? 36 : 26 }}>
        <EmailIcon style={{display: "block"}} />
      </div>
    </a>,
    <a
      href="https://linkedin.com/in/adamsmatt"
      className={styles.link}
    >
      <Linkedin size={isMobile ? 36 : 24} />
    </a>,
    <a
      href="https://github.com/madams1"
      className={styles.link}
    >
      <Github size={isMobile ? 36 : 24} />
    </a>
  ]

  return (
    <div className={styles.main}>
      <div
        className={`${styles.link} ${styles.homeLink}`}
        style={{
          color: setLinkColor(homeLinkHovered)
        }}
        onMouseOver={_ => setHomeLinkHovered(true)}
        onMouseOut={_ => setHomeLinkHovered(false)}
        onClick={_ => {
          setShowMenu(false)
          navigate("/")
        }}
      >
        Matt Adams
      </div>
     <div style={{height: "100%", paddingRight: 24, display: "flex"}}>
       {
        isMobile ?
          <div
            className={styles.link}
            style={{
              color: setLinkColor(hoveredLink === -1),
              paddingLeft: 6,
              paddingRight: 6
            }}
            onMouseOver={_ => setHoveredLink(-1)}
            onMouseOut={_ => setHoveredLink(null)}
            onClick={_ => setShowMenu(!showMenu)}
          >
            {showMenu ? <CloseIcon /> : <MenuIcon />}
          </div> : 
          links.map((link, i) => {
           return (
              <div
                key={`link-${i}`}
                style={{
                  color: setLinkColor(hoveredLink === i),
                  marginRight: i === 0 ? "2rem" : 10
                }}
                onMouseOver={_ => setHoveredLink(i)}
                onMouseOut={_ => setHoveredLink(null)}
              >
               {link}
             </div>
           )
         })
       }
      </div>
      { isMobile && showMenu && 
        <div className={styles.menu}>
          {
            links.map((link, i) => {
              return (
                <div
                  key={`link-${i}`}
                  style={{
                    color: setLinkColor(hoveredLink === i),
                    fontSize: 20,
                    height: 64,
                    background: hoveredLink === i ? "#cccccc33" : null,
                    transition: "background 0.3s ease-out 0.2s",
                    borderBottom: i === links.length - 1 ? null : "1px solid #e1e1e1"
                  }}
                  onMouseOver={_ => setHoveredLink(i)}
                  onMouseOut={_ => setHoveredLink(null)}
                  onClick={_ => setShowMenu(false)}
                >
                  {link}
                </div>
              )
            })
          }
        </div>
      }
    </div>
  )
}

export default Navbar
