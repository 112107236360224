import React from 'react'
import Masonry from 'react-masonry-css'
import ProjectGridItem from './ProjectGridItem'
import { useSiteData } from "../SiteDataProvider"
import "./styles.css"

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1
};

const ProjectGrid = () => {

  const { projects } = useSiteData()

  return (
    <div>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="project-grid"
        columnClassName="project-grid-col"
      >
        { 
          projects.map((project, i) => {
            return (
              <ProjectGridItem 
                key={`project-${i}`}
                project={project}
                extUrl={project.title === "Graphique" ? "https://graphique.dev" : null}
              />
            )
          })
        }
      </Masonry>
    </div>
  )
}

export default ProjectGrid
